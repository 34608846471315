import { Amplify } from 'aws-amplify';
import { Paper, Typography } from '@mui/material';
import { ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import '@aws-amplify/ui-react/styles.css';

import theme from './theme';

import awsconfig from './updatedAwsConfig';
import { useNavigate } from 'react-router-dom';

Amplify.configure(awsconfig);

const RedButton = styled(Button)({
  backgroundColor: '#ff0000', // Set the red color
  '&:hover': {
    backgroundColor: '#cc0000', // Darker red on hover
  },
  color: 'white' // Text color
});

const TeamManagerButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/manager');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
      <RedButton variant="contained" onClick={handleClick}>
        Become a Team Manager
      </RedButton>
    </div>
  );
}


export function App() {

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div className="welcome-container">
          <img src="/welcome_banner_mouth.png" alt="Welcome Logo" className="welcome-image"></img>
          <div className="banner-text"><b>You’ve never raced like this before!</b></div>
        </div>
        <Paper elevation={0} sx={{ width: '90%', m: 'auto', mt: '0px'}}>
        <Typography variant="body1" gutterBottom>
          <p>
          Join a thrilling race with friends and family where teamwork and communication will help your team reach its goal. Compete against other teams to achieve a set distance as swiftly as you can. The baton is passed to team mates by sending a screen shot of your activity to Twelfth Monkey. All you will need is your smartphone and the Strava app to track each step towards victory.
          </p>
          <p><b>How It Works:</b></p>
            <ol>
              <li><b>Form Your Team:</b> Gather your squad and appoint a team manager to lead the way.</li>
              <li><b>Track Your Progress:</b> Send a screenshot of your completed activity in Strava to Twelfth Monkey via SMS, automatically syncing with our system for seamless baton passing.</li>
              <li><b>Race to the Finish:</b> See each members efforts instantly update on the event graph/chart as you race against other teams to complete your challenge.</li>
            </ol>
          <p><b>Getting Started is Easy:</b></p>
            <ul>
              <li>Team Managers: 
                <ol>
                  <li>Sign up <a href="/manager">here</a></li>
                  <li>Create your team by adding their phone numbers one by one.</li>
                  <li>An SMS invite will be sent to each person.</li>
                </ol>
              </li>
              <li>Team Members: Join through the invitation link and get ready to race.</li>
            </ul>
        

          <p><b>Why Join?</b></p>
            <ul>
              <li><b>Connect and Compete:</b> Build bonds, embrace teamwork, and enjoy the spirit of competition from anywhere.</li>
              <li><b>Track and Triumph:</b> Monitor your team's progress and see how you rank against others in real-time.</li>
            </ul>
        

          <p><b>Ready to Race?</b>Dive into the excitement of the Twelfth Monkey Relay Race? Become a team manager (below).</p>

          <p><b>Questions?</b> <a href="mailto:support@twelfth-monkey.com">Email us</a></p>
          </Typography>
          <TeamManagerButton />
        </Paper>
      </ThemeProvider>
    </div>
  );
}



export default App;