import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000000', // Sets the default background to black
      paper: 'black'    // Optionally, sets the Paper component backgrounds to black
    }
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif', // Replace with your desired font family
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000', // Ensure the background is black
          boxShadow: 'none', // Remove shadow effects
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff', // Light color for the divider
          opacity: 0.8, // Adjust opacity to make the divider more noticeable
        }
      }
    }
  }
});

export default theme;