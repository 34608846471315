import { useEffect } from 'react';
import {  useSearchParams } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Paper, Typography } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import {  API, GraphQLQuery, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import {  addTeamToEvent, updatePurchase } from "./graphql/mutations";
import { getPurchase } from "./graphql/queries";
import { UpdatePurchaseMutation } from './API'; // Import the necessary types


import {  AddTeamToEventInput, AddTeamToEventMutation } from "./API";

import awsconfig from './updatedAwsConfig';
Amplify.configure(awsconfig);


export function TeamSignUp() {

  const [searchParams] = useSearchParams();

  useEffect(() => {
      // declare the data fetching function
      const registerTeamForEvent = async () => {
          try {
              // get the purchase id from the URL search params
              const purchaseId = searchParams.get('utm_content');

              // use the purchase id to fetch the purchase data
              const purchaseData = await API.graphql({
                query: getPurchase,
                variables: { id: purchaseId },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
              }) as any;

              console.log('purchaseData', purchaseData);

              // get the event id and team id from the purchase data
              const eventId = purchaseData.data.getPurchase?.event;
              const teamId = purchaseData.data.getPurchase?.team;


              // if the event id and team id are not found in the URL search params, throw an error
              if (!eventId || !teamId) {
                console.error('Event ID or Team ID is null');
                return;
              }


              // if the event is not found, simply return
              /*if (!event.getEvent) {
                return;
              }*/

              console.log('eventId', eventId);

              console.log('teamId', teamId); 

              // check the team id against the teams in the event, and skip if the team is already registered
              /*if (event.getEvent!.teams?.includes(teamId)) {
                return;
              }*/
              console.log('teamId', teamId);
              // otherwise mutate the event to include the team
              const input: AddTeamToEventInput = {
                  id: eventId,
                  team: teamId
              };
              await API.graphql({
                query: addTeamToEvent,
                variables: { input },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
              }) as GraphQLQuery<AddTeamToEventMutation>;


              // update the purchase to reflect that the team has been registered by setting status to true
              await API.graphql({
                query: updatePurchase,
                variables: { input: { id: purchaseId, status: true } },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
              }) as GraphQLQuery<UpdatePurchaseMutation>;

              setTimeout(() => {
                // redirect to the event page
                window.location.href = '/manager';
              }, 5000);

          } catch (err: any) {
              console.error(JSON.stringify(err));
          }
      }
      registerTeamForEvent();
    }
    , [searchParams]);


  return (
    <Paper elevation={0} sx={{ width: '95%', m: 'auto', mt: '20px'}}>
      <Typography variant="body1" gutterBottom>Thank you for signing up to the event</Typography> 
    </Paper>
  );
}

export default TeamSignUp;