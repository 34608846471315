/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const addIndividualToTeam = /* GraphQL */ `mutation AddIndividualToTeam($input: AddIndividualToTeamInput!) {
  addIndividualToTeam(input: $input) {
    id
    managerCognitoId
    managerPhoneNumber
    name
    invites
    members
    names
    batonHolder
    lastBatonPassTime
    badges
    locked
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddIndividualToTeamMutationVariables,
  APITypes.AddIndividualToTeamMutation
>;
export const addTeamToEvent = /* GraphQL */ `mutation AddTeamToEvent($input: AddTeamToEventInput!) {
  addTeamToEvent(input: $input) {
    id
    name
    invitesSent
    teams
    started
    archived
    minimumDistance
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddTeamToEventMutationVariables,
  APITypes.AddTeamToEventMutation
>;
export const createIndividual = /* GraphQL */ `mutation CreateIndividual(
  $input: CreateIndividualInput!
  $condition: ModelIndividualConditionInput
) {
  createIndividual(input: $input, condition: $condition) {
    id
    phoneNumber
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIndividualMutationVariables,
  APITypes.CreateIndividualMutation
>;
export const updateIndividual = /* GraphQL */ `mutation UpdateIndividual(
  $input: UpdateIndividualInput!
  $condition: ModelIndividualConditionInput
) {
  updateIndividual(input: $input, condition: $condition) {
    id
    phoneNumber
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIndividualMutationVariables,
  APITypes.UpdateIndividualMutation
>;
export const deleteIndividual = /* GraphQL */ `mutation DeleteIndividual(
  $input: DeleteIndividualInput!
  $condition: ModelIndividualConditionInput
) {
  deleteIndividual(input: $input, condition: $condition) {
    id
    phoneNumber
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIndividualMutationVariables,
  APITypes.DeleteIndividualMutation
>;
export const createTeam = /* GraphQL */ `mutation CreateTeam(
  $input: CreateTeamInput!
  $condition: ModelTeamConditionInput
) {
  createTeam(input: $input, condition: $condition) {
    id
    managerCognitoId
    managerPhoneNumber
    name
    invites
    members
    names
    batonHolder
    lastBatonPassTime
    badges
    locked
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamMutationVariables,
  APITypes.CreateTeamMutation
>;
export const updateTeam = /* GraphQL */ `mutation UpdateTeam(
  $input: UpdateTeamInput!
  $condition: ModelTeamConditionInput
) {
  updateTeam(input: $input, condition: $condition) {
    id
    managerCognitoId
    managerPhoneNumber
    name
    invites
    members
    names
    batonHolder
    lastBatonPassTime
    badges
    locked
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamMutationVariables,
  APITypes.UpdateTeamMutation
>;
export const deleteTeam = /* GraphQL */ `mutation DeleteTeam(
  $input: DeleteTeamInput!
  $condition: ModelTeamConditionInput
) {
  deleteTeam(input: $input, condition: $condition) {
    id
    managerCognitoId
    managerPhoneNumber
    name
    invites
    members
    names
    batonHolder
    lastBatonPassTime
    badges
    locked
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeamMutationVariables,
  APITypes.DeleteTeamMutation
>;
export const createEvent = /* GraphQL */ `mutation CreateEvent(
  $input: CreateEventInput!
  $condition: ModelEventConditionInput
) {
  createEvent(input: $input, condition: $condition) {
    id
    name
    invitesSent
    teams
    started
    archived
    minimumDistance
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventMutationVariables,
  APITypes.CreateEventMutation
>;
export const updateEvent = /* GraphQL */ `mutation UpdateEvent(
  $input: UpdateEventInput!
  $condition: ModelEventConditionInput
) {
  updateEvent(input: $input, condition: $condition) {
    id
    name
    invitesSent
    teams
    started
    archived
    minimumDistance
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventMutationVariables,
  APITypes.UpdateEventMutation
>;
export const deleteEvent = /* GraphQL */ `mutation DeleteEvent(
  $input: DeleteEventInput!
  $condition: ModelEventConditionInput
) {
  deleteEvent(input: $input, condition: $condition) {
    id
    name
    invitesSent
    teams
    started
    archived
    minimumDistance
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventMutationVariables,
  APITypes.DeleteEventMutation
>;
export const createEventData = /* GraphQL */ `mutation CreateEventData(
  $input: CreateEventDataInput!
  $condition: ModelEventDataConditionInput
) {
  createEventData(input: $input, condition: $condition) {
    id
    event
    team
    phoneNumber
    timeStarted
    timeFinished
    distance
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventDataMutationVariables,
  APITypes.CreateEventDataMutation
>;
export const updateEventData = /* GraphQL */ `mutation UpdateEventData(
  $input: UpdateEventDataInput!
  $condition: ModelEventDataConditionInput
) {
  updateEventData(input: $input, condition: $condition) {
    id
    event
    team
    phoneNumber
    timeStarted
    timeFinished
    distance
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventDataMutationVariables,
  APITypes.UpdateEventDataMutation
>;
export const deleteEventData = /* GraphQL */ `mutation DeleteEventData(
  $input: DeleteEventDataInput!
  $condition: ModelEventDataConditionInput
) {
  deleteEventData(input: $input, condition: $condition) {
    id
    event
    team
    phoneNumber
    timeStarted
    timeFinished
    distance
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventDataMutationVariables,
  APITypes.DeleteEventDataMutation
>;
export const createBadge = /* GraphQL */ `mutation CreateBadge(
  $input: CreateBadgeInput!
  $condition: ModelBadgeConditionInput
) {
  createBadge(input: $input, condition: $condition) {
    id
    name
    s3Key
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBadgeMutationVariables,
  APITypes.CreateBadgeMutation
>;
export const updateBadge = /* GraphQL */ `mutation UpdateBadge(
  $input: UpdateBadgeInput!
  $condition: ModelBadgeConditionInput
) {
  updateBadge(input: $input, condition: $condition) {
    id
    name
    s3Key
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBadgeMutationVariables,
  APITypes.UpdateBadgeMutation
>;
export const deleteBadge = /* GraphQL */ `mutation DeleteBadge(
  $input: DeleteBadgeInput!
  $condition: ModelBadgeConditionInput
) {
  deleteBadge(input: $input, condition: $condition) {
    id
    name
    s3Key
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBadgeMutationVariables,
  APITypes.DeleteBadgeMutation
>;
export const createPurchase = /* GraphQL */ `mutation CreatePurchase(
  $input: CreatePurchaseInput!
  $condition: ModelPurchaseConditionInput
) {
  createPurchase(input: $input, condition: $condition) {
    id
    team
    event
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePurchaseMutationVariables,
  APITypes.CreatePurchaseMutation
>;
export const updatePurchase = /* GraphQL */ `mutation UpdatePurchase(
  $input: UpdatePurchaseInput!
  $condition: ModelPurchaseConditionInput
) {
  updatePurchase(input: $input, condition: $condition) {
    id
    team
    event
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePurchaseMutationVariables,
  APITypes.UpdatePurchaseMutation
>;
export const deletePurchase = /* GraphQL */ `mutation DeletePurchase(
  $input: DeletePurchaseInput!
  $condition: ModelPurchaseConditionInput
) {
  deletePurchase(input: $input, condition: $condition) {
    id
    team
    event
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePurchaseMutationVariables,
  APITypes.DeletePurchaseMutation
>;
