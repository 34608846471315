import Paper from '@mui/material/Paper';
import { Typography, List, ListItem, Link, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import './logo.css'

export function Legal() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className='logo-container'>
        <div className='logo'></div>
      </div>
    <Paper elevation={0} sx={{ width: '95%', m: 'auto', mt: '20px'}}>
      <Typography variant="h3" gutterBottom>Terms of Use and Privacy</Typography>
      <Typography variant="h4" gutterBottom>Terms of Use</Typography>
      <Typography variant="body1" gutterBottom>You agree that:</Typography>
      <List sx={{
        listStyleType: 'disc',
        listStylePosition: 'inside'
      }}>
        <ListItem sx={{ display: 'list-item' }}>The following information (see next section) is collected, stored, and used in the manner as described.</ListItem>
        <ListItem sx={{ display: 'list-item' }}>The information is stored securely when in transit and at rest, using IT best practices, in the Amazon Web Services Sydney region in Australia (Eora nation).</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Managers have access to the names and mobile numbers only, for the purpose of running the events. You, and the system administrators, have full access to your own informtion as described in the next section.</ListItem>
        <ListItem sx={{ display: 'list-item' }}>We comply with the Australian Privacy Principles and the GDPR, and in particular all information can be updated or deleted on request and you have a right to access information held, via (at present) <Link href="mailto:support@twelfth-monkey.com">Email</Link>, or (in future) the web interface provided.</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Any security breaches will be clearly communicated to all users ASAP</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Any excerise taken as part of the virtual relay events is at your own risk. You should see your GP first if you have any health concerns or conditions. If you feel breathless or unwell at any point in time during the event you should stop, and seek medical attention as appropriate.</ListItem>
        <ListItem sx={{ display: 'list-item' }}>You should be respectful of others and of the law, and of the Indigenous nations on whom the events are held and the information stored and processed.</ListItem>
      </List>
      <Typography variant="h4" gutterBottom>Personal information held</Typography>
      <Typography variant="body1" gutterBottom>
        User information held directly, encrypted at rest and in transit, and why:
        <List sx={{
          listStyleType: 'disc',
          listStylePosition: 'inside'
        }}>
          <ListItem sx={{ display: 'list-item' }}>Name
            <List sx={{
              listStyleType: 'circle',
              listStylePosition: 'inside'
            }}>
              <ListItem sx={{ display: 'list-item' }}>For team management</ListItem>
              <ListItem sx={{ display: 'list-item' }}>For use in SMS relay notifications</ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>Email address
            <List sx={{
              listStyleType: 'circle',
              listStylePosition: 'inside'
            }}>
              <ListItem sx={{ display: 'list-item' }}>As an option for sign up / sign in</ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>Mobile phone
            <List sx={{
              listStyleType: 'circle',
              listStylePosition: 'inside'
            }}>
              <ListItem sx={{ display: 'list-item' }}>As an option for sign up / sign in</ListItem>
              <ListItem sx={{ display: 'list-item' }}>For sending SMS relay notifications</ListItem>
            </List>
          </ListItem>
        </List>
      </Typography>
      <Typography variant="body1" gutterBottom>
        User information obtained from uploaded activity screenshots:
        <List sx={{
          listStyleType: 'disc',
          listStylePosition: 'inside'
        }}>
          <ListItem sx={{ display: 'list-item' }}>Activity distance, time, and timezone, but not location / GPS, for all activities, but only saved as part of a team total when events are run, otherwise discarded.
          </ListItem>
        </List>
      </Typography>
      <Typography variant="body1" gutterBottom>
        User information optionally obtained from <Link href="https://google.com/">Google</Link>
        <List sx={{
          listStyleType: 'disc',
          listStylePosition: 'inside'
        }}>
          <ListItem sx={{ display: 'list-item' }}>Name and email address
          </ListItem>
        </List>
      </Typography>
    </Paper>
    </ThemeProvider>
  );
}


export default Legal;