import { useEffect, useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Icon from './Icon';
import { useAuthenticator } from '@aws-amplify/ui-react';


let pages = [
  { text: 'Home', href: '/' },
  { text: 'Team Manager', href: '/manager' },
  { text: 'Instructions', href: '/instructions' },
  { text: 'Support', href: 'https://monkey-chat.vercel.app'},
  { text: 'Legal & Privacy', href: '/legal' },
  { text: 'Results', href: '/results' }
];

//const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

interface User {
  signInUserSession?: {
    accessToken: {
      payload: {
        'cognito:groups': string[]
      }
    }
  }
}

function ResponsiveAppBar() {

  const navigate = useNavigate();

  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    const u: User = user as User;
    pages.push()
    if (u) {
      const payload = u.signInUserSession?.accessToken?.payload;
      const groups = payload ? payload['cognito:groups'] : [];



      if (groups) {
        if (groups.includes('administrators')) {
          if (pages.length === 5) {
            // insert event page after manager page
            pages.splice(2, 0, {
              text: 'Event',
              href: '/event'
            });
          }
        }
      }
    }
  }, [user]);

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
//  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  /*
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  */

  const handleCloseNavMenu = (to: string) => {
    if (to.startsWith('http')) {
      window
        .open(to, '_blank')
        ?.focus();
      return;
    }
    navigate(to);
    setAnchorElNav(null);
  };
/*
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  */

  return (
    <AppBar position="static" sx={{ backgroundColor: 'black' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          


          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.text} onClick={() => {
                  handleCloseNavMenu(page.href)}}>
                  <Typography textAlign="center">{page.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Icon />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.text}
                onClick={() => {
                  handleCloseNavMenu(page.href)
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.text}
              </Button>
            ))}
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;