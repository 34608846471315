import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { CssBaseline, Paper, Typography } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import {  API, GraphQLQuery } from '@aws-amplify/api';
import { addIndividualToTeam } from "./graphql/mutations";


import { AddIndividualToTeamInput, AddIndividualToTeamMutation } from "./API";


import { ThemeProvider} from '@mui/material/styles';
import theme from './theme';

import awsconfig from './updatedAwsConfig';

Amplify.configure(awsconfig);


export function SignUp() {

  const [searchParams] = useSearchParams();


  const [ teamName, setTeamName ] = useState('');

  const [ theError, setTheError ] = useState('');

  useEffect(() => {

      const updateTeamAndFetchTeamName = async () => {

        try {

          const teamId = searchParams.get('team');
          // phone number is uri encoded in the search params as 'phone'
          const phoneNumber = decodeURIComponent(searchParams.get('number') as string);

          console.log('teamId', teamId);
          console.log('phoneNumber', phoneNumber);

          if (teamId && phoneNumber) {

            const teamDetails: AddIndividualToTeamInput = {
              id: teamId,
              member: phoneNumber
            };


            try {

              // first fetch the team to check if the phone number is already in the team.invites
              // if it isn't, we don't need to do anything
              // if it is, we need to update the team with the individual
              const teamQuery = `query GetTeam($id: ID!) {
                getTeam(id: $id) {
                  id
                  invites
                }
              }`;
              const teamResponse = await API.graphql({
                query: teamQuery,
                variables: { id: teamId }
              }) as { data: GraphQLQuery<{ getTeam: { id: string, invites: string[] } }> };
              console.log('teamResponse', teamResponse);
              if (!teamResponse.data?.getTeam) {
                throw new Error('Team not found');
              }
              if (teamResponse.data.getTeam.invites.indexOf(phoneNumber) === -1) {
                throw new Error('Phone number not in team invites');
              }
              


              // Try to update the team with the individual
              // add the individual to the team
              const addIndividual = await API.graphql({
                query: addIndividualToTeam,
                variables: { input: teamDetails }
              }) as { data: AddIndividualToTeamMutation };

            // set teamName based on the addIndividual response
            setTeamName(addIndividual.data?.addIndividualToTeam?.name!);
            } catch (error) {
              console.error('Error during update:', error);
              // Check if error is due to conditional check failure
              if ((error as any).errors && (error as any).errors[0].errorType === "DynamoDB:ConditionalCheckFailedException") {
                // if so, use listTeams with a filter on the teamId to get the team name
                const teamNameQuery = `query ListTeams {
                  listTeams(filter: {id: {eq: "${teamId}"}}) {
                    items {
                      name
                    }
                  }
                }`;
                const teamNameResponse = await API.graphql({
                  query: teamNameQuery
                }) as { data: GraphQLQuery<{ listTeams: { items: { name: string }[] } }> };
                // set teamName based on the listTeams response
                setTeamName(teamNameResponse.data?.listTeams?.items![0].name!);
              } else {
                // If another error, throw it
                throw error;
              }
            }
  
          } 
          setTimeout(() => {
            window.location.href = '/instructions';
          }, 5000);
      } catch (error: any) {
        setTheError(error.message);
      }

    };
    
    updateTeamAndFetchTeamName();
  }, [searchParams]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <Paper elevation={0} sx={{ width: '95%', m: 'auto', mt: '20px'}}>
      {teamName ? (<Typography variant="h5" gutterBottom>Thank you for signing up. You are now in team {teamName}</Typography>) : (theError ? (<Typography variant='body1' gutterBottom>Sorry, something went wrong. {theError}</Typography>) : <></>) }
    </Paper>
    </ThemeProvider>
  );
}

export default SignUp;