/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "invite",
            "endpoint": "https://opydhy2q1b.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "startEvent",
            "endpoint": "https://8bsxizvvia.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "stats",
            "endpoint": "https://jx27kbc596.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://26qsm4dwg5fvrlolfh7fu3wtv4.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7vawc5brqfbmjalsbki77vtdhq",
    "aws_cognito_identity_pool_id": "ap-southeast-2:e96f4a78-9928-45de-b1e6-e2cc55c0e072",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_S2kTYPUSz",
    "aws_user_pools_web_client_id": "5v92u7f7tkjq81ov66fq1sdkc0",
    "oauth": {
        "domain": "tredheksim3e4bc7f5-3e4bc7f5-prod.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/manager/,https://twelfth-monkey.com/manager/,https://dev.twelfth-monkey.com/manager/",
        "redirectSignOut": "http://localhost:3000/,https://twelfth-monkey.com/,https://dev.twelfth-monkey.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "twelfth-monkey-badge-assets53939-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
