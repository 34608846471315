import { Paper, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import './logo.css'

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


const RedButton = styled(Button)({
  backgroundColor: '#ff0000', // Set the red color
  '&:hover': {
    backgroundColor: '#cc0000', // Darker red on hover
  },
  color: 'white' // Text color
});

const TeamManagerButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/manager');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
      <RedButton variant="contained" onClick={handleClick}>
        Sign in as a Team Manager
      </RedButton>
    </div>
  );
}

export function ManagerInstructions () {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className='logo-container'>
        <div className='logo'></div>
      </div>
    <Paper elevation={0} sx={{ width: '90%', m: 'auto', mt: '20px'}}>

      <Typography variant="body1" gutterBottom>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>

        <p>For support contact us by <a href="mailto:support@twelfth-monkey.com?subject=Support">email</a></p>
    
      <TeamManagerButton />
           </Typography>

    </Paper>
    </ThemeProvider>
  );
}

export default ManagerInstructions;