import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useNavigation
} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import { Authenticator } from "@aws-amplify/ui-react";
import awsconfig from './updatedAwsConfig';
import Instructions from './Instructions';
import Legal from './Legal';
import SignUp from './SignUp';
import TeamSignUp from './TeamSignUp';
import NavBar from './NavBar';
import Manager from './Manager';
import EventManagement from './EventManagement';
import ManagerInstructions from './ManagerInstructions';
import Results from './Results';

function ScrollToTop() {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state === 'idle') {
      window.scrollTo(0, 0);
    }
  }, [navigation]);

  return null;
}


const HeaderLayout = () => (
  <>
    <header>
      <NavBar />
    </header>
    <Outlet />
  </>
);

Amplify.configure(awsconfig);

const router = createBrowserRouter([
  {
    element: <HeaderLayout />,
    children: [

    {
      path: "/",
      element: <><ScrollToTop /><App /></>
    },
    {
      path: "/signup*",
      element: <><ScrollToTop /><SignUp /></>
    },
    {
      path: "/teamsignup*",
      element: <><ScrollToTop /><TeamSignUp /></>
    },
    {
      path: "/instructions",
      element: <><ScrollToTop /><Instructions /></>
    },
    {
      path: "/legal",
      element: <><ScrollToTop /><Legal /></>
    },
    {
      path: "/managerInstructions",
      element: <><ScrollToTop /><ManagerInstructions /></>
    },
    {
      path: "/manager",
      element: <><ScrollToTop /><Manager /></>
    },
    {
      path: "/event",
      element: <><ScrollToTop /><EventManagement /></>
    },
    { 
      path: "/results",
      element: <><ScrollToTop /><Results /></>
    }
    ]
  }
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Authenticator.Provider>
      <RouterProvider router={router} />
    </Authenticator.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.log('redirect', awsconfig.oauth.redirectSignIn);
