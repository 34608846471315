import { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

import './graph.css';

interface GraphProps {
  id: string;
}

export default function Graph({ id }: GraphProps) {

  const isDev = window.location.hostname === 'localhost' || window.location.hostname.startsWith('192.168.') || window.location.hostname.startsWith('10.') || window.location.hostname.startsWith('dev');

  const API_URL = isDev ? 'https://stats-api-dev.twelfth-monkey.com/' : 'https://stats-api.twelfth-monkey.com/';

  const initialized = useRef(false)


  // have a function that runs when the page is loaded
  useEffect(() => {

    if (initialized.current) {
      return;
    }
    // Fetch data from the API and render charts
    async function fetchData() {
      initialized.current = true;
      try {

        console.log('fetching data');
        let finishDistance = 25; // this is the overall length of the race for the whole team in kms.

        const response = await fetch(`${API_URL}${id}`);
        const data = await response.json();

        if (data.message && data.message === 'Event not found') {
          document.getElementById(`${id}-event-name`)!.innerText = data.message;
          return;
        }
        const { distance, name, eventData } = data;

        if (!distance || !name || !eventData || eventData.length === 0) {
          console.error("Invalid data received from API: ", data);
          return;
        }
        finishDistance = distance * 50; // this is the overall length of the event in kms.
      
        // Display the event name by setting the innerText of the element with the id 'event-name'
        document.getElementById(`${id}-event-name`)!.innerText = `Event: ${name}`;
      
        const totalDistanceByTeam = eventData.reduce((acc: any, { teamName }: any) => {
          if (!acc[teamName]) {
            acc[teamName] = 0;
          }
          acc[teamName] += distance;
          return acc;
        }, {});
      
      
        const teamNames = new Set(eventData.map(({ teamName }: any) => teamName));
        const seriesByTeam = Array.from(teamNames).map(teamName => ({
          name: teamName,
          // Sum the distance values for the team and wrap it in an array to conform to the expected data structure
          data: [totalDistanceByTeam[teamName as string]]
        }));
      
        console.log('seriesByTeam', seriesByTeam);
        
      
        // now we need to create a new object that will store the cumulative distance data by date, by team.
        const distanceDataByTeam: { [key: string]: [number, number][] } = {};
      
        // use the teamNames set to store the unique team names as keys in the object, initialize the value as an empty array
        teamNames.forEach((teamName) => {
          distanceDataByTeam[teamName as string] = [];
        });
      
        // for each eventData item, push the date and cumulative distance to the array for the team in the object
        eventData.forEach(({ teamName, timeFinished }: any) => {
          const lastDistanceIndex = distanceDataByTeam[teamName].length - 1;
          const lastDistance = lastDistanceIndex >= 0 ? distanceDataByTeam[teamName][lastDistanceIndex][1] : 0;
          const cumulativeDistance = lastDistance + distance;
          distanceDataByTeam[teamName].push([new Date(timeFinished*1000).getTime(), cumulativeDistance]);
        });
      
      
      
        // data.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime()).forEach(event => {
        //   teamNames.add(event.team);
        //   distanceDataByTeam[event.team] = distanceDataByTeam[event.team] || [];
        //   // Set each event's distance to 10m directly without cumulative addition
        //   distanceDataByTeam[event.team].push([new Date(event.start_date).getTime(), 10]);
        // });
        console.log('distanceDataByTeam');
        console.log(distanceDataByTeam);
        
      
        const seriesByDate = Array.from(teamNames).map(team => ({
          name: team,
          data: distanceDataByTeam[team as string]
        }));
      
        console.log('seriesByDate');
        console.log(seriesByDate);
      
        // Chart options
        const optionsByTeam = {
          chart: {
              type: 'bar',
              background: 'black',
          },
          grid: {
            padding: {
              top: 20,
              right: 70,
              bottom: 20,
              left: 20
            }
          },
          series: seriesByTeam,
          plotOptions: {
              bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded',
              },
          },
          theme: {
            mode: 'dark'
          },
          annotations: {
            yaxis: [{
                y: finishDistance,
                borderColor: 'red',
                strokeWidth: 10,
                label: {
                    borderColor: 'red',
                    style: {
                        color: 'white',
                        background: 'red',
                    },
                    text: 'FINISH'
                }
            }],
          },
          dataLabels: {
              enabled: false
          },
          stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
          },
          xaxis: {
              categories: ['Teams'], // Adjust based on your data or requirements
          },
          yaxis: {
              title: {
                  text: 'Kms'
              },
              max: finishDistance,
              min: 0,
              decimalsInFloat: 1,
          },
          
          fill: {
              opacity: 1
          },
          tooltip: {
              y: {
                  formatter: function (val: any) {
                      return val + " kms"
                  }
              },
              style: {
                fontSize: '11px'
              }
          },
          responsive: [{
            breakpoint: 1024,
            options: {
              chart: {
                height: '100%',
              },
              xaxis: {
                labels: {
                  style: {
                    // fontSize: '10px',
                    
                  }
                },
              },
              yaxis: {
                max: finishDistance,
                min: 0,
                decimalsInFloat: 1,
                labels: {
                  style: {
                    //fontSize: '10px'
                  }
                },
                  title: {
                    text: 'Distance (km)',
                    style: {
      
                    //fontSize: '10px',
                    },
                  },
              },
              max: 1000,
              min: 0,
              markers: {
                //size: 10
              },
              legend: {
                //fontSize: '10px',
                
              },
            },
          }]
        };
      
        const optionsByDate = {
          chart: {
            type: 'line',
            animations: {
              enabled: false
            },
            zoom: {
              enabled: false // disable zooming because of Safari bug
            },
            background: 'black',
          },
          grid: {
            padding: {
              top: 20,
              right: 70,
              bottom: 20,
              left: 20
            }
          },
          xaxis: {
            type: 'datetime',
              labels: {
                format: 'dd/MM/yyyy',
                datetimeUTC: false,
                // Optionally, specify the formatter function for more complex scenarios
              },
              // Additional xaxis configurations...
            },
            yaxis: {
              title: {
                  text: 'Kms'
              },
              max: finishDistance,
              min: 0,
              decimalsInFloat: 1,
          },
          series: seriesByDate,
          theme: {
            mode: 'dark'
          },
          legend: {
            show: true, 
            position: 'bottom',
          },
          tooltip: {
            x: {
              format: 'dd/MM/yyyy'
            },
            y: {
              formatter: function (value: any) {
                return value.toFixed(2) + ' km';
              }
            },
            style: {
              fontSize: '11px'
            }
          },
          markers: {
            size: 5,
          },        
          annotations: {
            yaxis: [{
                y: finishDistance,
                borderColor: 'red',
                strokeWidth: 10,
                label: {
                    borderColor: 'red',
                    style: {
                        color: 'white',
                        background: 'red',
                    },
                    text: 'FINISH'
                }
            }],
            zIndex: 3, // This will draw the annotations on top of the grid lines
        
          },
          responsive: [{
            breakpoint: 1024,
            options: {
              chart: {
                height: '100%',
              },
              xaxis: {
                labels: {
                  style: {
                    // fontSize: '30px'
                  }
                },
              },
              yaxis: {
                labels: {
                  style: {
                    // fontSize: '20px'
                  },
        
                },
                title: {
                  text: 'Distance (km)',
                  style: {
      
                  // fontSize: '20px',
                  },
                },
                max: finishDistance,
                min: 0,
                decimalsInFloat: 1,
              },
      
              legend: {
                // fontSize: '20px',
                
              },
              markers: {
                size: 5
              }
            }
          }]
        };
        
      
        // Initialize chart
        var chart3 = new ApexCharts(document.querySelector(`#${CSS.escape(id)}-chartByTeam`), optionsByTeam);
        chart3.render();
        // Render the line chart
        var chart = new ApexCharts(document.querySelector(`#${CSS.escape(id)}-chartByDate`), optionsByDate);
        chart.render();

        console.log('charts rendered');
        

      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    fetchData();
  }, [API_URL, id]);

  return (


    <div className="bg-black-100 p-4 md:p-6 lg:p-8 max-w-screen-lg mx-auto" style={{ fontFamily: 'beatStreet' }}>
    
      {/* <div className="flex items-center justify-center mb-1 md:mb-2"> */}
        { /* Logo */ }
        {/* <div className="logo-container mr-4 flex-none" style={{ width: '162px', height: '208px', backgroundImage: "url('/public/Black+with+outline.png')", backgroundSize: 'cover', backgroundPosition: 'center' }}></div> */}

        {/* Title */}
        {/* <h1 className="text-8xl  text-white">12th Monkey Stats</h1> */}

      {/*</div>*/}
      <h2 className="text-5xl  text-center text-white mb-4" id={`${id}-event-name`}>Event</h2>

      {/* First Chart Container */}
      <div className="mb-8 p-4 bg-black shadow rounded-lg">
        <h3 className="text-lg font-semibold mb-4">Distance x Team</h3>
        <div id={`${id}-chartByTeam`} className="h-60 lg:h-auto"></div>
      </div>

      {/* Second Chart Container */}
      <div className="p-4 bg-black shadow rounded-lg">
        <h3 className="text-lg font-semibold mb-4">Distance x Date</h3>
        <div id={`${id}-chartByDate`} className="h-60 lg:h-auto"></div>
      </div>
    </div>



  );


}

