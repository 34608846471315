export default function Icon() {
  return(
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="32px" viewBox="0 0 928 1024" enableBackground="new 0 0 928 1024">
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M396.997528,108.267990 
	C399.276337,109.393608 400.804901,109.620598 402.663818,107.760376 
	C406.829956,103.591278 410.052521,103.641663 414.190338,107.820892 
	C421.607849,115.312721 428.110138,123.378502 432.171875,133.374557 
	C438.820190,149.736191 445.352600,165.957779 447.652924,183.767105 
	C450.051636,202.338425 448.581696,220.246979 442.559143,237.854355 
	C440.837280,242.888260 438.802460,247.820435 436.767517,252.739838 
	C435.637482,255.471634 435.484131,257.631012 438.102081,259.789062 
	C441.281281,262.409790 441.357208,265.487732 438.909912,269.028137 
	C434.268280,275.743073 429.332825,282.142639 422.407715,287.026825 
	C423.193115,287.933105 423.540619,288.489136 424.025635,288.870026 
	C429.556915,293.213989 429.327728,297.920074 423.244171,301.335480 
	C404.272003,311.986633 384.574921,320.167847 362.834229,324.079742 
	C329.721252,330.037903 298.299286,341.069611 269.265594,358.775085 
	C239.483398,376.937073 212.384094,398.241913 188.355728,423.456635 
	C182.851257,429.232880 178.068604,435.691559 172.819717,441.719482 
	C170.723526,444.126740 171.783508,446.393311 172.569977,448.726166 
	C182.786377,479.029358 197.062073,506.943604 219.062164,530.656311 
	C239.920532,553.138428 264.282379,570.271729 292.755524,581.597656 
	C293.374542,581.843933 294.002136,582.073914 294.638306,582.271118 
	C308.690674,586.626465 309.526917,586.359985 318.468842,574.996521 
	C330.113525,560.198364 345.128174,549.536438 361.311737,540.371643 
	C364.307648,538.675049 366.976746,537.248047 367.470581,533.295654 
	C367.820618,530.494141 370.050629,528.895996 372.667053,527.709961 
	C389.321136,520.160706 406.990112,517.226562 425.045135,516.107910 
	C428.107117,515.918152 430.379852,515.387451 431.835724,512.256958 
	C433.518127,508.639343 437.036530,507.851410 440.725586,507.922272 
	C449.089691,508.082886 457.273071,509.719879 465.483765,511.083038 
	C471.725922,512.119324 477.936157,513.347168 484.163208,514.475403 
	C486.189941,514.842529 488.205872,515.215149 488.971802,512.451294 
	C490.502106,506.929230 494.303162,507.449615 498.487213,508.900208 
	C511.551941,513.429749 524.655701,517.801819 538.138062,521.002502 
	C550.159485,523.856323 562.169861,525.952087 574.631226,526.187683 
	C594.731995,526.567444 612.421143,519.321533 629.787109,510.572968 
	C634.395142,508.251526 638.819336,505.567780 643.368713,503.126312 
	C646.061523,501.681122 647.421753,500.264923 645.625854,496.857819 
	C642.866089,491.621735 644.615479,487.835022 650.499695,486.299408 
	C657.413818,484.495087 664.449097,483.100800 671.632507,482.713379 
	C674.242859,482.572601 676.990845,482.945374 679.191345,481.120361 
	C679.280823,480.690063 679.460693,480.345306 679.397705,480.052826 
	C677.352600,470.555664 680.181580,467.671448 689.858643,469.185150 
	C694.217224,469.866852 698.422607,471.627228 702.566284,471.481903 
	C703.657654,470.433044 703.464355,469.608704 703.336792,468.782196 
	C702.839966,465.562683 704.324463,463.311554 706.939514,461.702423 
	C709.871521,459.898224 712.347229,461.245178 714.740845,462.974426 
	C724.886902,470.304260 731.462830,480.485809 737.278076,491.258026 
	C741.003723,498.159454 744.316956,505.255096 746.368896,512.855591 
	C747.286682,516.255493 749.418335,517.370422 752.708313,516.922302 
	C756.666077,516.383057 760.622192,515.815125 764.593750,515.399597 
	C767.812622,515.062927 770.916321,515.394531 773.454041,517.777771 
	C781.713257,525.533997 785.775330,548.286804 780.966064,558.715149 
	C777.506226,566.217468 770.822266,570.142456 764.173218,573.807068 
	C758.201965,577.098206 752.775879,580.240356 750.544800,587.334717 
	C749.146484,591.780823 745.349915,594.471375 741.215820,596.629700 
	C726.286438,604.424438 711.112427,611.684631 695.091492,616.944214 
	C689.750183,618.697693 687.931885,621.579163 688.259949,627.211060 
	C688.999878,639.914185 686.292419,652.392456 684.795471,664.944580 
	C683.908691,672.379456 683.194092,679.837219 682.170654,687.252747 
	C681.451233,692.466003 683.766785,696.157593 687.227112,699.633362 
	C698.154968,710.609924 709.443054,721.243408 719.665527,732.901367 
	C730.013916,744.703003 739.387939,757.219849 746.612488,771.220886 
	C749.801025,777.400024 750.591614,777.717407 757.015808,774.980286 
	C760.385010,773.544800 763.816650,772.244751 767.264221,771.006104 
	C779.588623,766.578247 790.153625,769.852905 799.426697,778.514709 
	C803.943420,782.733643 807.995789,787.383545 811.779480,792.261230 
	C817.524536,799.667419 815.662842,804.805542 806.507263,807.119507 
	C799.483887,808.894531 792.252197,808.070923 785.135193,808.919983 
	C777.464600,809.835083 770.033691,811.411072 762.771484,813.920837 
	C755.352539,816.484741 748.689026,816.282837 741.388245,812.065613 
	C728.149109,804.417908 716.684265,794.543640 704.728760,785.275330 
	C695.771240,778.331116 686.538574,771.790833 677.178223,765.431702 
	C662.335205,755.347778 648.853821,743.829712 637.759033,729.627686 
	C634.380493,725.302856 633.793945,725.409668 630.781860,730.179626 
	C629.116577,732.816711 627.328613,735.356934 623.789612,734.849609 
	C620.034668,734.311279 618.939270,731.229370 618.106201,728.043823 
	C616.566223,722.155029 616.955383,716.140137 616.811951,710.154114 
	C616.774475,708.592163 617.450378,706.861328 615.587708,704.858643 
	C611.921692,714.618164 605.446472,722.911804 609.662109,734.235046 
	C619.196838,759.845337 624.055115,786.859558 632.575439,812.793701 
	C634.641663,819.082947 634.871338,819.294250 641.452087,817.808289 
	C652.725342,815.262695 663.336060,816.395691 672.744385,823.668945 
	C678.113037,827.819153 681.411865,833.196045 679.803833,840.130859 
	C678.223511,846.945862 672.845886,849.920105 666.397339,851.266541 
	C659.841553,852.635437 653.199097,852.990051 646.524048,853.152954 
	C640.119812,853.309143 633.996033,854.753479 628.220764,857.616455 
	C626.285156,858.575928 624.292480,859.467590 622.244995,860.146362 
	C607.480164,865.040588 597.181702,858.019958 596.943359,842.454285 
	C596.823669,834.636902 593.355835,828.342102 589.530151,822.097412 
	C577.865906,803.057922 566.256531,784.016602 558.303406,763.001160 
	C554.451294,752.822327 551.357056,742.482544 550.179626,731.658936 
	C549.768311,727.877869 548.597656,726.098083 544.364136,726.108459 
	C538.907410,726.121765 536.223633,722.563171 537.219055,717.073486 
	C538.253540,711.368103 539.137268,705.556335 542.466248,700.530457 
	C543.258789,699.333984 544.203003,697.986389 542.908569,695.998352 
	C538.795288,696.057678 534.728943,697.546265 530.691406,698.740112 
	C528.453003,699.401978 529.157043,701.743042 529.726501,703.514771 
	C530.898865,707.162476 531.929382,710.872253 533.366089,714.415527 
	C538.241760,726.440186 539.429749,739.250671 541.590820,751.857300 
	C542.286438,755.915100 541.596313,759.511353 538.103821,762.126099 
	C536.598999,763.252686 536.389038,764.851929 536.168701,766.535522 
	C533.637451,785.879089 530.997192,805.187805 522.401184,823.071228 
	C520.547607,826.927551 523.174438,828.257080 526.398621,828.557556 
	C532.090576,829.087952 537.677856,828.066650 543.259460,827.079468 
	C553.520691,825.264648 563.384277,821.131653 574.222839,822.789978 
	C591.149414,825.379822 601.207214,845.183350 592.243286,858.762085 
	C590.092163,862.020630 587.140259,863.916138 583.349976,862.530212 
	C573.613892,858.969788 565.099854,862.038208 557.528931,867.655090 
	C532.788635,886.009888 493.729797,877.963745 478.257141,846.372253 
	C473.634491,836.933960 471.364502,827.697205 472.230194,817.187683 
	C473.287720,804.349731 470.982422,791.620300 468.652985,779.015503 
	C465.174286,760.191589 461.340027,741.439270 456.038452,723.016235 
	C455.068878,719.647095 453.469452,717.623413 449.745972,718.211914 
	C434.449890,720.629700 419.549164,718.919189 404.931335,714.046570 
	C404.358490,713.855713 403.666443,714.022278 402.143707,714.022278 
	C403.719177,719.923889 405.060791,725.470215 406.689087,730.931091 
	C409.364594,739.903687 410.332153,749.206360 412.062714,758.352966 
	C413.633453,766.655212 411.630585,769.493713 403.879944,772.376831 
	C402.070984,773.049805 400.577881,774.014343 399.709625,775.728516 
	C382.785767,809.139709 352.707092,826.291138 320.071686,840.372986 
	C312.206329,843.766724 303.777924,845.108154 295.716217,847.767639 
	C291.975006,849.001831 290.967316,850.295898 293.571381,853.592346 
	C298.348724,859.639893 303.677460,865.138794 309.860352,869.717712 
	C317.550232,875.412781 326.259094,877.679260 335.799530,876.602600 
	C336.627136,876.509216 337.449463,876.370361 338.275543,876.262512 
	C358.005493,873.685425 364.331665,880.854431 367.872131,901.648193 
	C368.430359,904.926880 367.344635,907.940979 364.262512,909.846558 
	C361.418610,911.604858 358.766724,910.790405 356.350739,909.035156 
	C353.907440,907.260132 351.567169,907.356445 348.850067,908.416443 
	C344.970062,909.929993 341.067627,911.340332 337.388367,913.332947 
	C324.625397,920.245239 311.319183,921.866394 297.347473,917.478699 
	C281.168091,912.397827 265.570831,906.229126 253.327698,893.883179 
	C242.197800,882.659851 234.589539,869.329407 230.142349,854.172546 
	C228.764938,849.478149 231.066574,845.336487 232.107361,841.031128 
	C232.690796,838.617554 232.949677,836.830017 230.822250,834.786133 
	C225.496201,829.669067 226.920624,823.859619 234.360413,822.518494 
	C249.140854,819.853821 262.256500,813.443237 274.573212,805.468079 
	C297.516296,790.612183 318.343079,773.341370 333.419708,750.036072 
	C334.973541,747.634216 335.214905,745.484558 333.577698,743.124023 
	C330.041840,738.026062 327.604736,732.362732 325.117737,726.718933 
	C324.525360,725.374695 323.870453,723.608521 322.735809,723.024719 
	C314.731903,718.906677 313.442627,710.890381 311.135620,703.510681 
	C308.299866,694.439758 306.925201,685.031311 305.091339,675.730774 
	C304.839081,674.451538 304.737274,672.713623 303.890167,672.048096 
	C296.816437,666.490356 298.183167,658.750000 298.423157,651.386719 
	C298.612457,645.578369 299.236420,639.781494 299.760651,633.987915 
	C300.070221,630.566528 298.620605,628.991943 295.207397,629.115601 
	C279.074585,629.700195 263.343719,626.770447 247.792175,623.078979 
	C235.945892,620.267029 224.578934,615.791687 213.843475,609.988159 
	C201.831711,603.494690 189.072098,598.542969 177.296036,591.526001 
	C165.754898,584.649048 155.664062,576.024048 145.617813,567.282410 
	C142.715378,564.756836 140.796875,561.629089 142.247513,557.551453 
	C143.290634,554.619324 141.370163,553.328552 139.516312,551.823608 
	C129.747116,543.893127 121.525269,534.521545 114.174187,524.358826 
	C111.400452,520.524231 109.506989,516.602600 113.019241,512.081116 
	C114.484894,510.194366 113.580498,508.300018 112.396523,506.551971 
	C107.427437,499.215546 103.701607,491.202667 99.819267,483.280182 
	C96.997833,477.522675 98.553604,472.651337 104.255089,470.172180 
	C106.633492,469.138000 107.142548,467.750153 106.738136,465.518677 
	C101.801033,438.276550 106.352486,411.825714 113.458519,385.597961 
	C114.020935,383.522125 114.607727,381.366608 115.672852,379.532501 
	C117.493553,376.397339 120.655304,374.905640 123.725517,376.963837 
	C127.827599,379.713837 129.610565,377.688354 131.619125,374.571808 
	C135.499786,368.550476 139.493530,362.601654 143.473633,356.644928 
	C146.175247,352.601562 149.578842,350.419098 154.451401,353.008728 
	C157.003235,354.364990 158.498672,352.621094 160.183655,351.323334 
	C180.437790,335.723267 201.917053,322.029816 225.060577,311.175385 
	C252.561340,298.277374 281.285278,288.786255 310.821533,281.661591 
	C324.088226,278.461456 337.244110,274.773956 350.369293,271.024109 
	C359.825867,268.322388 364.442627,260.120392 369.333862,252.678650 
	C379.110413,237.804398 385.848816,221.516830 389.926453,204.188553 
	C391.372192,198.044678 392.287445,191.776215 393.455566,185.566635 
	C393.998596,182.679932 391.852600,181.320023 390.072296,179.923264 
	C367.723022,162.388916 342.713196,150.401840 315.027771,143.971222 
	C306.241302,141.930344 297.420410,140.160355 288.367249,141.183136 
	C279.928894,142.136444 277.480621,144.968491 277.212891,153.493469 
	C276.779541,167.291061 281.851593,179.214401 290.073914,189.941330 
	C293.105499,193.896362 295.528687,193.500687 297.927521,189.076965 
	C300.427307,184.467178 301.516998,179.362976 302.504517,174.331131 
	C304.812653,162.570374 313.213257,156.737183 325.391663,158.019165 
	C347.698212,160.367310 356.278198,179.775375 354.731720,196.968567 
	C353.190582,214.102188 344.695587,227.801071 330.588623,237.758331 
	C327.865540,239.680389 327.041351,241.333206 328.871857,244.267441 
	C330.922577,247.554672 331.792816,251.006866 329.095245,254.490891 
	C326.343353,258.044983 322.772858,258.374969 318.756592,257.306946 
	C286.325195,248.682632 256.875946,235.322784 237.992783,205.629303 
	C233.087006,197.915070 228.424225,189.984955 225.640244,181.155426 
	C224.884384,178.758255 223.655853,177.231064 220.775574,176.977982 
	C215.007736,176.471161 211.986343,172.717133 212.514633,166.840805 
	C212.886536,162.704056 213.604477,158.590561 214.336258,154.496765 
	C214.840164,151.677658 214.412857,149.996292 211.271606,148.802383 
	C205.395172,146.568924 204.070221,141.523682 207.980804,136.337799 
	C217.706497,123.440399 230.337158,113.947754 244.825424,107.157829 
	C256.380951,101.742332 267.950897,96.468369 280.913727,94.404427 
	C297.648560,91.739914 314.091553,92.901314 330.643677,95.085236 
	C348.537170,97.446144 366.138489,100.988556 382.844208,108.055267 
	C387.522675,110.034309 392.052155,106.583092 396.997528,108.267990 
z"/>
</svg>
  );
}